var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{staticClass:"mb-0",attrs:{"footer-tag":"footer"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('b-button-loading',{attrs:{"text":"AGREGAR","type":"submit","processing":_vm.processing}})]},proxy:true}])},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"md":"4"}},[_c('label',{attrs:{"for":"name"}},[_c('strong',[_vm._v("NOMBRE DEL PROVEEDOR")])]),_c('validation-provider',{attrs:{"rules":"required","name":"nombre del proveedor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"Ingrese el nombre del proveedor ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"4"}},[_c('label',{attrs:{"for":"ruc"}},[_c('strong',[_vm._v("RUC")])]),_c('validation-provider',{attrs:{"rules":"required","name":"RUC"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ruc","placeholder":"Ingrese el RUC ...","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.formData.ruc),callback:function ($$v) {_vm.$set(_vm.formData, "ruc", $$v)},expression:"formData.ruc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"4"}},[_c('label',{attrs:{"for":"businessArea"}},[_c('strong',[_vm._v("RUBRO")])]),_c('validation-provider',{attrs:{"rules":"required","name":"rubro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"businessArea","state":errors.length > 0 ? false : null,"options":_vm.options.businessAreas},model:{value:(_vm.formData.businessArea),callback:function ($$v) {_vm.$set(_vm.formData, "businessArea", $$v)},expression:"formData.businessArea"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-2",attrs:{"md":"4"}},[_c('label',{attrs:{"for":"nextDateCycle"}},[_c('strong',[_vm._v("PRÓXIMA FECHA DEL CICLO")])]),_c('validation-provider',{attrs:{"rules":"required","name":"próxima fecha del ciclo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"nextDateCycle","state":errors.length > 0 ? false : null,"config":_vm.onlyDateConfig,"placeholder":"YYYY-MM-DD"},model:{value:(_vm.formData.nextDateCycle),callback:function ($$v) {_vm.$set(_vm.formData, "nextDateCycle", $$v)},expression:"formData.nextDateCycle"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.clearDate('nextDateCycle')}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"12"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},[_c('label',{attrs:{"for":"contractNumbers"}},[_c('strong',[_vm._v("NÚMERO(S) DE CONTRATO(S)")])]),_c('b-form-textarea',{staticClass:"char-textarea",attrs:{"id":"contractNumbers","placeholder":"Número(s) de contrato(s) ...","rows":"3"},model:{value:(_vm.formData.contractNumbers),callback:function ($$v) {_vm.$set(_vm.formData, "contractNumbers", $$v)},expression:"formData.contractNumbers"}}),_c('small',{staticClass:"textarea-counter-value float-right"},[_c('strong',{staticClass:"char-count"},[_vm._v("Caracteres: "+_vm._s(_vm.formData.contractNumbers ? _vm.formData.contractNumbers.length : 0))])])],1),_c('b-col',{attrs:{"md":"12"}},[_c('label',{attrs:{"for":"contractExpirations"}},[_c('strong',[_vm._v("FECHA(S) DE VENCIMIENTO DE LOS CONTRATOS")])]),_c('b-form-input',{attrs:{"id":"contractExpirations","trim":""},model:{value:(_vm.formData.contractExpirations),callback:function ($$v) {_vm.$set(_vm.formData, "contractExpirations", $$v)},expression:"formData.contractExpirations"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }