<template>
    <validation-observer ref="refForm">

        <b-form
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
        >

            <b-row>
                <b-col md="12">
                    <b-card footer-tag="footer" class="mb-0">

                        <b-row>

                            <!-- PROVIDER NAME -->
                            <b-col md="4" class="mb-2">
                                <label for="name"><strong>NOMBRE DEL PROVEEDOR</strong></label>
                                <validation-provider
                                    #default="{ errors }"
                                    rules="required"
                                    name="nombre del proveedor"
                                >
                                    <b-form-input
                                        id="name"
                                        v-model="formData.name"
                                        placeholder="Ingrese el nombre del proveedor ..."
                                        :state="errors.length > 0 ? false : null"
                                        trim
                                    />

                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>

                            <!-- RUC -->
                            <b-col md="4" class="mb-2">
                                <label for="ruc"><strong>RUC</strong></label>
                                <validation-provider
                                    #default="{ errors }"
                                    rules="required"
                                    name="RUC"
                                >
                                    <b-form-input
                                        id="ruc"
                                        v-model="formData.ruc"
                                        placeholder="Ingrese el RUC ..."
                                        :state="errors.length > 0 ? false : null"
                                        trim
                                    />

                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>

                            <!-- BUSINESS AREA -->
                            <b-col md="4" class="mb-2">
                                <label for="businessArea"><strong>RUBRO</strong></label>
                                <validation-provider
                                    #default="{ errors }"
                                    rules="required"
                                    name="rubro"
                                >
                                    <v-select
                                        id="businessArea"
                                        v-model="formData.businessArea"
                                        :state="errors.length > 0 ? false : null"
                                        :options="options.businessAreas"
                                    />

                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>

                            <!-- START DATE CYCLE -->
                            <b-col md="4" class="mb-2">
                                <label for="nextDateCycle"><strong>PRÓXIMA FECHA DEL CICLO</strong></label>
                                <validation-provider
                                    #default="{ errors }"
                                    rules="required"
                                    name="próxima fecha del ciclo"
                                >
                                    <b-input-group>
                                        <flat-pickr
                                            id="nextDateCycle"
                                            v-model="formData.nextDateCycle"
                                            :state="errors.length > 0 ? false : null"
                                            class="form-control"
                                            :config="onlyDateConfig"
                                            placeholder="YYYY-MM-DD"
                                        />
                                        <b-input-group-append>
                                            <b-button
                                                variant="primary"
                                                @click="clearDate('nextDateCycle')"
                                            >
                                                <feather-icon
                                                    icon="XIcon"
                                                    size="12"
                                                />
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-col>

                            <!-- CONTRACT NUMBERS -->
                            <b-col md="12" class="mb-1">
                                <label for="contractNumbers"><strong>NÚMERO(S) DE CONTRATO(S)</strong></label>
                                <b-form-textarea
                                    id="contractNumbers"
                                    v-model="formData.contractNumbers"
                                    class="char-textarea"
                                    placeholder="Número(s) de contrato(s) ..."
                                    rows="3"
                                />
                                <small class="textarea-counter-value float-right">
                                    <strong class="char-count">Caracteres: {{ formData.contractNumbers ? formData.contractNumbers.length : 0 }}</strong>
                                </small>
                            </b-col>

                            <!-- CONTRACT EXPIRATIONS -->
                            <b-col md="12">
                                <label for="contractExpirations"><strong>FECHA(S) DE VENCIMIENTO DE LOS CONTRATOS</strong></label>
                                <b-form-input 
                                    id="contractExpirations" 
                                    v-model="formData.contractExpirations" 
                                    trim
                                />
                            </b-col>

                        </b-row>

                        <template #footer>

                            <!-- BUTTON SUBMIT -->
                            <b-button-loading 
                                text="AGREGAR" 
                                type="submit"
                                :processing="processing">
                            </b-button-loading>

                        </template>

                    </b-card>
                </b-col>
            </b-row>

        </b-form>

    </validation-observer>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import BButtonLoading from '@core/components/b-button-loading/BButtonLoading.vue';
    import { ref, onBeforeMount, onUnmounted } from '@vue/composition-api';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { useToast } from 'vue-toastification/composition';
    import providerStoreModule from '../providerStoreModule';
    import { Spanish } from 'flatpickr/dist/l10n/es';
    import flatPickr from 'vue-flatpickr-component';
    import { required } from '@validations';
    import vSelect from 'vue-select';
    import router from '@/router';
    import moment from 'moment';
    import store from '@/store';
    import axios from '@axios';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonLoading,

            flatPickr,
            required,
            vSelect
        },
        setup() {

            // USE TOAST
            const toast = useToast();

            // REFS
            const refForm = ref(null);

            const onlyDateConfig = ref({
                enableTime: false,
                locale: Spanish,
                minDate: moment().format('YYYY-MM-DD')
            });

            const formData = ref({
                name: null,
                ruc: null,
                businessArea: null,
                nextDateCycle: null,
                contractNumbers: null,
                contractExpirations: null
            });

            const processing = ref(false);
            const options = ref({});

            const PROVIDER_APP_STORE_MODULE_NAME = 'app-provider';

			// REGISTER MODULE
			if (!store.hasModule(PROVIDER_APP_STORE_MODULE_NAME)) store.registerModule(PROVIDER_APP_STORE_MODULE_NAME, providerStoreModule);

			// UNREGISTER ON LEAVE
			onUnmounted(() => {
				if (store.hasModule(PROVIDER_APP_STORE_MODULE_NAME)) store.unregisterModule(PROVIDER_APP_STORE_MODULE_NAME);
			});

            // CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
            onBeforeMount( () => {
                getSelectFilters();
            });

            const getSelectFilters = async () => {
                const { data } = await axios.get('/selects/business-areas');
                options.value = data;
            };

            const resetFormData = () => {
                formData.value = {
                    name: null,
                    ruc: null,
                    businessArea: null,
                    nextDateCycle: null,
                    contractNumbers: null,
                    contractExpirations: null
                }
            };

            const clearDate = (index) => {
                formData.value[index] = null;
            };

            const onSubmit = async () => {

                const isValid = await refForm.value.validate();

                if (!isValid)
                {
                    toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Complete los campos requeridos.',
                            icon: 'AlertTriangleIcon',
                            variant: 'warning'
                        }
                    });
                    
                    return;
                }

                processing.value = true;

                const payload = {
                    name: formData.value.name,
                    ruc: formData.value.ruc,
                    business_area_id: formData.value.businessArea.value,
                    next_date_cycle: formData.value.nextDateCycle,
                    contract_numbers: formData.value.contractNumbers,
                    contract_expirations: formData.value.contractExpirations
                }

                store.dispatch('app-provider/createProvider', payload)
                    .then( response => {

                        toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'CheckIcon',
                                variant: 'success'
                            }
                        });

                        resetFormData();
                        refForm.value.reset();

                        setTimeout(() => {
                            router.push({ name: 'providers-edit', params: { id: response.data.id } });
                        }, 2000);

                    })
                    .catch( (err) => {
                        let message = err.response.data.message ? err.response.data.message : 'Error al crear el proveedor.';
                        
                        toast({
                            component: ToastificationContent,
                            props: {
                                title: message,
                                icon: 'AlertTriangleIcon',
                                variant: 'danger'
                            }
                        });

                        processing.value = false
                    });

            };

            return {
                // DATA
                onlyDateConfig,
                processing,
                formData,
                options,

                // REFS
                refForm,

                // METHODS
                clearDate,
                onSubmit
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>